@import "../../commonStyles.scss";

.root {
  display: inline-block;
  width: 120px;
  min-width: 120px;
  height: 120px;
  padding: 10px;
  .content {
    position: relative;
    height: 100%;
    overflow: hidden;
    .title,
    .value {
      position: absolute;
      text-align: center;
      left: 0px;
      right: 0px;
      max-height: 60px;
    }
    .title {
      font-size: 14px;
      color: var(--neutralDark);
      top: 2px;
      vertical-align: top;
    }
    .value {
      padding: 10px 0px;
      font-weight: 400;
      color: var(--themePrimary);
      top: 40px;
      bottom: 20px;
      line-height: 40px;
      vertical-align: middle;
    }
  }
}
@media only screen and (max-width: $tinyScreenWidth) {
  .root {
    width: 95px;
    height: 95px;
    .content {
      zoom: 0.8;
    }
  }
}
