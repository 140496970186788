@import "../../common/commonStyles.scss";

.appModuleContent {
  display: flex;
  flex-direction: column;
}
.pivot {
  flex: 1;
  position: relative;
}
.pivotItem {
  padding: 10px 0px;
  margin-left: -10px;
}
.editor {
  border: 1px solid var(--neutralLight);
  background-color: var(--white);
  position: absolute;
  top: 60px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow: hidden;
}
.error,
.successMessage {
  padding-bottom: 10px;
}
.error {
  color: darkred;
}
.successMessage {
  color: darkgreen;
}
.errorDetails {
  padding-top: 20px;
  color: var(--neutralSecondary);
}
.errorDetailsItem {
  padding-top: 4px;
}
.validateButton {
  padding-top: 8px;
}
