.hint {
  background-color: var(--neutralLight);
  color: var(--neutralPrimary);
  font-size: 12px;
  border-radius: 6px;
  padding: 12px;
  .valuePane {
    .value {
      color: white;
      padding: 2px 8px;
      border-radius: 4px;
    }
    font-size: 16px;
    padding-bottom: 10px;
  }
  .category {
    font-weight: 200;
    padding-right: 10px;
  }
  .value {
    font-weight: 600;
  }
}
.valueSeriesSelectable:hover {
  cursor: pointer;
}
.hint:hover {
  cursor: pointer;
}
