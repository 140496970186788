@import "./ConfigItemEdit.module.scss";

.root {
  padding: 20px;
  overflow: hidden;
  height: 80vh;
  width: 90vw;
  .title {
    font-size: 24px;
    margin-top: 0;
    padding-top: 0;
    color: var(--neutralPrimary);
    margin-bottom: 0;
    padding-bottom: 0;
    overflow: visible;
  }
}

.editor {
  top: 70px;
}
