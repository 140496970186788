@import "../common/commonStyles.scss";

.detailsHeaderPane {
  color: var(--neutralPrimary);
}
.archived {
  display: inline-block;
  font-size: 12px;
  padding: 3px 6px;
  margin-right: 6px;
  border-radius: 4px;
  color: var(--white);
  background-color: var(--warning);
}
