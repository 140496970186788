.root {
  :global(.ms-Nav-compositeLink) {
    background-color: var(--neutralLight);
    color: var(--neutralPrimary);
  }
  :global(.ms-Nav-chevronButton) {
    background-color: transparent;
  }
  :global(.ms-Nav-chevronButton::after) {
    border-left-width: 0px;
  }
  :global(.ms-Nav-group > .ms-Nav-chevronButton) {
    font-weight: 600;
    font-size: 15px;
    padding-left: 38px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    height: 46px;
    border-bottom-color: var(--neutralQuaternaryAlt);
  }
  :global(.ms-Nav-groupContent) {
    margin-bottom: 10px;
  }
  :global(.ms-Nav-chevron) {
    left: 12px;
  }
  :global(.ms-Nav-linkText) {
    padding-left: 6px;
  }
  :global(.ms-Nav-navItems) {
    margin-block-start: 0px;
    :global(.ms-Nav-chevron),
    :global(.ms-Nav-link) {
      height: 36px;
      line-height: 36px;
    }
  }

  :global(.ms-Nav-navItem > .ms-Nav-compositeLink.is-selected) {
    :global(.ms-Button) {
      background-color: var(--themeLight);
      color: var(--themeDarker);
    }
    :global(.ms-Nav-linkText) {
      font-weight: 600;
    }
    :global(.ms-Nav-chevronButton) {
      background-color: transparent;
    }
  }
}
.menuButtonPane {
  height: 44px;
  width: 36px;
  position: relative;
}
.menuButtonPane:hover {
  cursor: pointer;
  background: var(--themeLight);
}
.menuButton {
  line-height: 44px;
  color: var(--neutralPrimary);
}
.homeLinkPane {
  position: absolute;
  top: 0px;
  height: 44px;
  line-height: 44px;
  right: 0px;
  left: 38px;
  text-align: left;
  vertical-align: middle;
  font-size: 14px;
  text-decoration: none;
  color: var(--neutralPrimary);
}
.homeLinkPane:focus {
  border: 1px solid #777;
}
.homeLinkSelected {
  background-color: var(--themeLight);
}
.menuPane:hover {
  background-color: var(--neutralLighterAlt);
  cursor: pointer;
}
.spinner {
  padding-top: 40px;
}
.error {
  padding: 20px 10px;
}
