.servicebusMessageExplorer {
  padding-right: 20px;

  .servicebusMessageExplorerConfig {
    padding-left: 0px;
  }

  .divcontent {
    display: flex;
    justify-content: left;
  }

  .checkButton {
    margin-left: 0px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .servicebusMessageSummaryResults {
    margin-right: 0px;
  }
  .servicebusMessagePeekResults {
    margin-right: 0px;
  }
}
