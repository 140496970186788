@import "./commonStyles.scss";

.root {
  :global(.ms-Breadcrumb) {
    margin-left: -8px;
    margin-top: 6px;
  }
  .messageContent {
    margin: 0px -16px 0px -40px;
  }
  .subHeader {
    font-size: 14px;
    font-weight: 100;
    color: var(--neutralSecondary);
  }
  .commandBar {
    border-bottom: 1px solid var(--neutralQuaternary);
    margin: 0px -16px 0px -40px;
    min-height: 44px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: flex-end;
    background-color: var(--neutralLight);
    :global(.ms-CommandBar) {
      background-color: var(--neutralLight);
      padding: 0px 40px;
      :global(.ms-Button) {
        background-color: var(--neutralLight);
      }
      :global(.ms-Button:hover) {
        background-color: var(--neutralLighter);
      }
    }
  }
}
.headerPane {
  background-color: var(--neutralLighter);
  padding-top: 14px;
  padding-bottom: 8px;
  margin-left: -10px;
  padding-left: 10px;
  height: 60px;
}
.tightPane {
  padding: 0px;
}
@media only screen and (max-width: $smallScreenWidth) {
  .root {
    .commandBar {
      margin: 0px 0px 0px -20px;
      :global(.ms-CommandBar) {
        padding: 0px 20px 0px 40px;
      }
    }
  }
}
.header {
  font-weight: bold;
  margin: 0;
  float: left;
}
.backButton {
  float: left;
  margin-top: 18px;
  margin-left: -10px;
}
.backButtonIcon {
  font-size: 28px;
}
.srOnly {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
.headerContent {
  position: relative;
}
