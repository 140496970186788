@import "../common/commonStyles.scss";

.root {
  font-family: Roboto, arial, sans-serif;
  font-size: 14px;
  padding: 10px 0px;
  .link {
  }
  .title {
    font-size: 20px;
    padding-top: 8px;
    padding-bottom: 6px;
    color: var(--themeDarker);
  }
}
