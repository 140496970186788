@import "../common/commonStyles.scss";

.field {
  padding: 10px 0px;
  .fieldName {
    font-weight: 400;
  }
  .fieldValue {
  }
  .groupNamePane {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    padding-top: 8px;
    input {
      width: 200px;
    }
    .groupNameStatusIcon {
      padding-left: 10px;
    }
  }
}
.impactScoreColor {
  display: inline-block;
  color: white;
  padding: 2px;
  min-width: 80px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  text-align: center;
}
