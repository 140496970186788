@import "../../commonStyles.scss";

.root {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.customTile {
  height: 306px;
}
.spinner {
  padding: 10px;
}
.lastInRowField {
  width: 100%;
  height: 1px;
}
.inputVisual {
  padding-bottom: 20px;
  padding-right: 20px;
}

@media only screen and (max-width: $smallScreenWidth) {
  .customTile {
    width: 100%;
  }
}
