.root {
  background: var(--white);
  min-width: 180px;
  padding: 10px;
  .mePane,
  .settingsPane {
    h4 {
      padding-top: 8px;
      border-top: 1px solid var(--neutralLighter);
    }
  }
  .linkPane {
    border-top: 1px solid var(--neutralLighter);
    text-align: center;
  }
  .link {
    padding: 6px 10px;
  }
  .link:hover {
    cursor: pointer;
    color: var(--themePrimary);
  }
  .mePane {
    display: flex;
    padding-bottom: 0px;
    .mePhoto {
      height: 48px;
      width: 48px;
      border-radius: 50%;
      margin-right: 10px;
    }
    .meInfoPane {
    }
    .meName {
      display: block;
      font-weight: 600;
    }
    .meInfo {
      font-size: 12px;
      font-weight: 300;
    }
  }
  h4 {
    color: var(--themeSecondary);
    margin-bottom: 10px;
    margin-block-start: 8px;
    font-weight: 400;
    text-transform: uppercase;
  }
}
