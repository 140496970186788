@import "../common/commonStyles.scss";

.headerPane {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  .headerText {
    display: inline-block;
    font-weight: 500;
    padding: 20px 0px;
  }
}
.filtersPane {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0px 0px 10px 0px;
  .filter {
    display: inline-block;
  }
  .teamsDropdown,
  .statusDropdown,
  .streamIdsDropdown,
  .timeDropdown {
    width: 140px;
    margin-left: 20px;
  }
  .streamIdsDropdown,
  .timeDropdown {
    width: 180px;
  }
}
.contentBox {
  overflow: auto;
  margin: 0px;
  border-width: 0px;
}
.lastRefreshTime {
  padding: 10px;
  font-size: 12px;
  font-weight: 300;
}
.streamStatusText {
  color: white;
  padding: 2px 6px;
  border-radius: 2px;
  width: 72px;
  text-align: center;
}
.text {
  padding-top: 10px;
}
