.root {
  vertical-align: middle;
  padding: 0px 6px;
  .icon {
    color: var(--themeDark);
  }
  .icon:hover {
    font-weight: 600;
    cursor: pointer;
  }
}
.root:focus {
  border: 1px dotted #777;
}
.callout {
  margin: 4px 10px;
  font-size: 12px;
}
