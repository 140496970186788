@import "../../commonStyles.scss";

.tooltip {
  font-size: 11px;
  .valuePane {
    .value {
      color: white;
      padding: 2px 8px;
      border-radius: 4px;
    }
    padding-bottom: 10px;
  }
  .seriesName {
    font-weight: 400;
    padding-right: 10px;
    white-space: nowrap;
  }
  .value {
    font-weight: 700;
  }
  .category {
    font-size: 12px;
    font-weight: 600;
    padding-bottom: 4px;
    padding-right: 10px;
  }
}
.verticalLegend,
.chartWithVerticalLegend {
  vertical-align: top;
  display: inline-block;
}
.legendContainer {
  overflow-y: auto;
  overflow-x: hidden;
  display: inline-block;
}
.horizontalLegend {
  text-align: center;
}
.legendItem {
  padding: 6px;
  white-space: nowrap;
}
.horizontalLegend .legendItem {
  display: inline-block;
}
.legendBullet {
  width: 12px;
  height: 12px;
  margin-right: 3px;
  margin-top: 3px;
  display: inline-block;
  vertical-align: top;
}
.legendTitle {
  font-size: 12px;
  display: inline-block;
  white-space: pre-line;
  vertical-align: top;
}
.valueSeriesSelectable:hover {
  cursor: pointer;
}
.hint:hover {
  cursor: pointer;
}
:global(.rv-discrete-color-legend-item) {
  padding: 5px 10px;
}
:global(.rv-discrete-color-legend-item__color) {
  margin-top: -3px;
}
:global(.rv-discrete-color-legend-item__title) {
  color: var(--neutralPrimary);
}
:global(.rv-xy-plot__axis__tick__text) {
  fill: var(--neutralSecondary);
}
