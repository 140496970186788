@import "../common/commonStyles.scss";

.root {
  padding-top: 10px;
  .entityTile {
    padding: 0px 20px;
    .header {
      font-size: 15px;
      font-weight: 500;
      padding: 10px 0px 16px 0px;
    }
  }
}
.message {
  padding: 20px;
}
