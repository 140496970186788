@import "../common/commonStyles.scss";

.root {
  padding-right: 36px;
  .filters {
    float: right;
    min-width: 280px;
  }
}
@media only screen and (max-width: $smallScreenWidth) {
  .root {
    margin-top: 10px;
    margin-bottom: -10px;
  }
}
