.header {
  height: 46px;
  background-color: var(--themePrimary);
  color: var(--neutralLighterAlt);
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  padding: 0px;
  position: relative;
}
.titlePane {
  color: white;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  justify-self: flex-start;
}
.title {
  padding-right: 10px;
}
.authPane {
  position: relative;
  margin-left: auto;
  padding: 0px 10px;
  font-size: 20px;
  display: flex;
  align-items: center;
}
.userPhoto {
  margin-left: 6px;
  img {
    margin-top: 4px;
    height: 36px;
    width: 36px;
    border-radius: 50%;
  }
  .userIcon {
    margin: 0px 4px;
  }
}
.searchIcon {
  color: white;
}
.searchIcon,
.menuIcon {
  margin: 0px 10px;
}
.searchIcon:hover,
.userIcon:hover,
.menuIcon:hover {
  cursor: pointer;
  color: var(--themeLighter);
}
.logo {
  height: 32px;
  padding-right: 10px;
  vertical-align: middle;
}
.navMenu {
  height: 46px;
  width: 36px;
  line-height: 46px;
  text-align: center;
  vertical-align: middle;
  margin-right: -8px;
}
.navMenu:hover {
  background-color: var(--themeSecondary);
  cursor: pointer;
}
.prodSecondary,
.staging,
.nonProd {
  margin: 0px 4px;
  padding: 4px 8px;
  background-color: var(--redDark);
  font-size: 12px;
  font-weight: 600;
  border-radius: 6px;
  white-space: nowrap;
}
.prodSecondary,
.nonProd {
  background-color: var(--neutralPrimary);
}
