@import "../../shared/commonStyles.scss";

.appModuleContent {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  text-align: left;
  padding: 0px 16px 0px 36px;
  box-sizing: border-box;
}
.appModuleContentCenter {
  max-width: 1124px;
  width: 84%;
  text-align: left;
}
.detailsHeaderPane {
  display: flex;
  border-left: 10px solid var(--neutralTertiary);
  .statusPane {
    padding: 10px 20px;
    text-align: center;
    align-self: center;
    min-height: 100%;
    .id,
    .status {
      padding-bottom: 6px;
      white-space: nowrap;
    }
    .id,
    .severity {
      font-size: 14px;
    }
  }
  .headerContent {
    border-left: 1px solid var(--neutralQuaternaryAlt);
    align-self: center;
    padding: 10px 20px;
    width: 100%;
    .dataFieldsPane {
      border-top: 1px solid var(--neutralQuaternaryAlt);
      padding-top: 8px;
      margin-top: 12px;
    }
  }
}
.dataFieldsPane {
  font-size: 12px;
  font-weight: 400;
  .dataField {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 14px;
    color: var(--neutralSecondary);
    padding-right: 40px;
    padding-bottom: 3px;
    line-height: 22px;
    .dataFieldName {
      padding-right: 8px;
      font-weight: 600;
      white-space: nowrap;
    }
  }
}
.entryField {
  display: inline-block;
  min-width: 60px;
  padding-bottom: 20px;
  padding-right: 20px;
  vertical-align: top;
  .fieldLabel {
    color: var(--neutralPrimary);
    font-size: 12px;
    font-weight: 400;
    padding-bottom: 2px;
  }
}
.entryField.fullWidth {
  padding-right: 0px;
}
.impactScore {
  display: inline-block;
  border-radius: 4px;
  color: white;
  min-width: 24px;
  text-align: center;
}
.editableItems {
  .item {
    font-size: 12px;
    padding: 8px 12px;
  }
  .itemHeader {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 2px;
  }
  .itemContact {
    display: inline-block;
  }
  .itemTime {
    padding-left: 8px;
    color: var(--neutralSecondary);
  }
  .itemActions {
    display: none;
    padding-left: 0px;
    padding-top: 2px;
    line-height: 12px;
  }
  .itemAction {
    padding-right: 0px;
  }
  .itemAction:hover {
    cursor: pointer;
    color: var(--themePrimary);
  }
  .itemSpinner {
    padding-left: 4px;
  }
  .itemSpinnerCircle {
    border-top-color: var(--themePrimaryDarker);
  }
  .itemSpinnerLabel {
    color: var(--themePrimaryDarker);
  }
  .item:hover {
    background-color: var(--themeLighterAlt);
  }
  .item:hover .itemActions {
    display: inline-block;
  }
}
.message {
  color: var(--neutralPrimary);
}
.successMessage {
  color: var(--neutralSecondary);
  font-size: 14px;
  font-weight: 200;
}
.successIcon {
  color: green;
  padding-right: 4px;
  font-size: 16px;
  vertical-align: text-bottom;
}
.spinner {
  padding: 20px;
}
.spinnerCircle {
  border-top-color: var(--themePrimaryDarker);
}
.spinnerLabel {
  font-size: 16px;
  color: var(--themePrimaryDarker);
}
.impactScoreColumn {
  text-align: center;
}
:global(.ms-ContextualMenu-header) {
  font-size: 14px;
  padding-left: 4px;
}
:global(.ms-DetailsHeader-cellName) {
  font-size: 12px;
}
:global(.ms-DetailsHeader) {
  padding-top: 0px;
}
:global(.ms-Pivot) {
  margin: 0px -10px;
}
:global(.ms-DetailsRow-cell[data-automation-key="actions"]) {
  padding: 8px 4px;
}
:global(.ms-DetailsRow-cell[data-automation-key="detailsPageLink"]) {
  padding: 4px;
  text-align: center;
}
.pivotItem {
  padding: 20px 0px 10px 0px;
}
.headerLessPivotItem {
  padding-top: 0px;
}
.selectable {
  :global(.ms-List-cell:hover) {
    cursor: pointer;
  }
}
.showAllToggle {
  text-align: right;
  padding-right: 20px;
  padding-bottom: 6px;
}
.reportDescription {
  padding-bottom: 16px;
  font-size: 16px;
  color: var(--neutralPrimary);
  font-weight: 400;
}
.footerInfo {
  font-size: 12px;
  font-weight: 100;
  color: var(--neutralPrimary);
  display: flex;
  justify-content: flex-start;
  padding-bottom: 10px;
  .footerInfoItem {
    padding-right: 20px;
    display: inline-block;
  }
}
.verticalText {
  writing-mode: vertical-lr;
  padding: 10px;
}
#pageNumbers {
  color: var(--neutralPrimary);
}
:global(.columnAlignCenter) {
  text-align: center;
}
:global(.statusIcon) {
  padding-top: 2px;
  font-size: 14px;
  vertical-align: middle;
}

@media only screen and (max-width: $smallScreenWidth) {
  .appModuleContent {
    padding: 0px 0px 0px 20px;
  }
  .detailsHeaderPane {
    display: block;
    .statusPane,
    .headerContent {
      width: auto;
    }
    .headerContent {
      border-left-width: 0px;
      border-top: 1px solid var(--neutralQuaternaryAlt);
      .datafieldsPane {
        .dataField {
          display: block;
        }
      }
    }
  }
  .dataFieldsPane {
    .dataField {
      padding-bottom: 6px;
    }
  }
}
.buttonAsHyperlink {
  background: none !important;
  border: none;
  padding: 0 !important;
  font-size: 12px;
  /*optional*/
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", "Helvetica Neue", sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  cursor: pointer;
}
.buttonAsHyperlink:hover {
  text-decoration: underline;
}
