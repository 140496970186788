@import "../../commonStyles.scss";

.svg {
  width: 100%;
  height: 100%;
}
.rect {
  fill: var(--neutralTertiaryAlt);
}
.tickLine {
  stroke-width: 1px;
  stroke: var(--neutralTertiary);
}
.flagText {
  color: white;
  padding: 4px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
.flagLine {
  stroke-width: 1px;
  stroke: var(--neutralTertiary);
}
text {
  fill: var(--neutralPrimary);
}
.marker {
  stroke-width: 0px;
  fill-opacity: 0.6;
  fill: black;
}
.markerValueText {
  fill: white;
  font-size: 10px;
}
.errorText {
  width: 100%;
  height: 100%;
}
.currentMarkerName {
  font-size: 12px;
  text-anchor: middle;
}
