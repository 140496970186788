@import "../../common/commonStyles.scss";

.root {
  display: flex;
  flex-wrap: wrap;
  .outlierTile {
    height: 306px;
    .content {
      height: 100%;
    }
  }
}

@media only screen and (max-width: $smallScreenWidth) {
  .root {
    display: block;
  }
}
