@import "../../commonStyles.scss";

.root {
  position: absolute;
  box-sizing: border-box;
  border-style: solid;
  border-width: 2px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.node {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: inherit;
  align-items: inherit;
  .content {
    display: inline-block;
  }
}
.dragging {
  position: absolute;
  box-sizing: border-box;
  border-style: solid;
  border-width: 2px;
  border-radius: 4px;
  cursor: grabbing;
  opacity: 0.5;
}
.draggable:hover {
  cursor: grab;
}
.icon {
  padding: 2px 4px;
  vertical-align: bottom;
}
.selected {
  font-weight: 600;
}
.infoPane {
  position: absolute;
  display: inline-block;
  color: var(--neutralPrimary);
  margin: 4px;
  padding: 4px;
  background-color: transparent;
  opacity: 0.75;
  border-radius: 10px;
}
.selected .infoPane {
  margin: 1px;
}
.infoPaneRight {
  top: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.infoPaneLeft {
  top: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.metric {
  white-space: nowrap;
  display: table-row;
}
.metricName,
.metricValue {
  display: table-cell;
  padding: 1px;
}
.metricName {
  padding-right: 6px;
  font-weight: 400;
  text-align: right;
}
.metricValue {
  font-weight: 700;
  text-align: left;
}
.nodeTooltip {
  max-width: 480px;
  padding: 20px;
  .spacing {
    height: 14px;
  }
}
.tooltipMetric {
  padding: 4px;
}
.node.clickable:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
:global(.diagramNodeStandardLabel),
:global(.diagramNodeStandardLabel:hover) {
  background-color: #ddd !important;
  color: #333;
}
