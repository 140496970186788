.error,
.noDataText {
  font-size: 12px;
  padding: 12px;
  color: var(--neutralSecondary);
}
.templateRoot {
  height: 100%;
}
.root {
  :global(.ms-Label) {
    padding-top: 0px;
    font-weight: 400;
    display: block;
  }
  .labelValue {
    padding-top: 6px;
    font-weight: 500;
  }
  :global(.ms-TextField-field) {
    padding-right: 36px;
  }
}
