.percentVisual {
  position: relative;
  font-size: 16px;
  font-weight: 700;
  border-radius: 24px;
  height: 44px;
  line-height: 44px;
  vertical-align: middle;
  border-width: 3px;
  border-style: solid;
  animation-duration: 2s;
  animation-name: slideup;
}
.htmlContent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
@keyframes slideup {
  0% {
    background: linear-gradient(white 100%, #deecf9 100% 0%);
  }
  50% {
    background: linear-gradient(white 95%, #deecf9 95% 5%);
  }
  55% {
    background: linear-gradient(white 90%, #deecf9 90% 10%);
  }
  60% {
    background: linear-gradient(white 80%, #deecf9 80% 20%);
  }
  65% {
    background: linear-gradient(white 70%, #deecf9 70% 30%);
  }
  70% {
    background: linear-gradient(white 60%, #deecf9 60% 40%);
  }
  75% {
    background: linear-gradient(white 50%, #deecf9 50% 50%);
  }
  80% {
    background: linear-gradient(white 40%, #deecf9 40% 60%);
  }
  85% {
    background: linear-gradient(white 30%, #deecf9 30% 70%);
  }
  90% {
    background: linear-gradient(white 20%, #deecf9 20% 80%);
  }
  95% {
    background: linear-gradient(white 10%, #deecf9 10% 90%);
  }
  100% {
    background: linear-gradient(white 0%, #deecf9 0% 100%);
  }
}
