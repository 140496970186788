@import "../common/commonStyles.scss";

.detailsHeaderPane {
  color: var(--neutralPrimary);
  .desc {
    padding-left: 10px;
    font-weight: 200;
  }
  .rating {
    width: 100px;
    padding-top: 6px;
  }
  .dataFieldValue {
    max-width: 480px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.ratingTooltipContainer {
  .ratingTooltipItem {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 6px 10px;
    .ratingTooltipName {
      font-size: 12px;
      padding-left: 6px;
    }
  }
  .ratingTooltipLink {
    padding: 6px 10px;
    text-align: center;
  }
}
