.ratingRoot {
  .category {
    padding: 10px 0px;
    .header {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      padding: 10px 0px;
      border-top: 1px solid var(--neutralLight);
      align-items: center;
      .text {
        padding-left: 10px;
      }
    }
    .kpi {
      width: 100px;
      height: 80px;
      border: 1px solid var(--neutralTertiaryAlt);
      background-color: var(--neutralLight);
      text-align: center;
      vertical-align: middle;
      margin-right: 20px;
    }
  }
}
