@import "../common/commonStyles.scss";

.searchPane {
  display: flex;
}
.searchBox {
  width: 400px;
  margin: 0px 20px 20px 0px;
}
.optionPane {
  display: flex;
  .optionCheckbox {
    padding: 6px 10px;
  }
}
