@import "./commonStyles.scss";

.collapseToggleIcon {
  position: absolute;
  right: 20px;
  top: -40px;
  padding: 10px;
}
.collapseToggleIconRight {
  right: -16px;
  top: 0px;
}
.collapseToggleIcon:hover {
  cursor: pointer;
  background-color: var(--neutralLight);
}
@media only screen and (max-width: $smallScreenWidth) {
  .collapseToggleIcon {
    display: none;
  }
}
