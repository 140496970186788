@import "../common/commonStyles.scss";

.root {
  border: 1px solid var(--neutralLight);
}
.trendIndicatorUp,
.trendIndicatorDown {
  width: 0px;
  height: 0px;
  display: inline-block;
  margin-bottom: 2px;
  margin-left: 4px;
}
.trendIndicatorUp {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid green;
}
.trendIndicatorDown {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid red;
}
