@import "./management.common.scss";

.appModuleHeader {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 10;
}
.errorMsg {
  color: red;
}

.upperTabStrip {
  margin-top: -72px;
  min-width: 750px;
  z-index: 100;
  position: relative;
}

.rightToolsArea {
  float: right;
}

.extraSpacingRightToolsArea {
  @extend .rightToolsArea;
  margin-left: 10px;
}

.editor {
  top: 190px;
}

.editorWithMessage {
  @extend .editor;
  top: 220px;
}
.testResult {
  font-size: 14px;
  max-width: 90vw;
  max-height: 80vh;
}
:global(.ms-ContextualMenu-item .ms-Pivot-text) {
  white-space: nowrap;
}
.envDropdown {
  position: absolute;
  width: 140px;
  right: 10px;
  top: -8px;
}
