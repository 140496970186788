@import "../../common/commonStyles.scss";

.groupingContent {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .timelineTile {
    flex: auto;
    min-width: 280px;
  }
}
.resultHistoryDropdown {
  min-width: 240px;
}
.resultHistoryDropdownLabel {
  font-weight: 400;
}
.resultStatusDropdown {
  min-width: 70px;
  margin: 0px 5px;
  text-align: left;
}
.resultMenuPane {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
@media only screen and (max-width: $mediumScreenWidth) {
  .groupingContent {
    display: block;
  }
}
