@import "../common/commonStyles.scss";

.root {
  border: 1px solid var(--neutralLight);
  .status {
    color: white;
    padding: 2px 4px;
    border-radius: 4px;
    min-width: 40px;
    text-align: center;
    display: inline-block;
  }
}
