@import "./management.common.scss";

.spinnerPane {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  .spinnerLabel {
    font-size: 12px;
    padding-left: 10px;
  }
}
.message {
  padding: 12px;
}
.editableItems > div.text {
  display: inline-block;
  min-width: 30px;
}
.itemActionsPane {
  padding: 0px 4px;
}
.itemAction {
  padding: 0px;
  width: 22px;
  height: 22px;
}
.soxConfirmModal {
  min-width: 800px;
  min-height: 380px;
}
