@import "../common/commonStyles.scss";

.root {
  margin-top: -22px;
  margin-right: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  .search,
  .filters,
  .views {
    display: inline-block;
    min-width: 200px;
    margin-left: 10px;
  }
  .views {
    min-width: 240px;
  }
}
@media only screen and (max-width: $smallScreenWidth) {
  .root {
    margin-top: -10px;
    flex-wrap: wrap;
    .search {
      width: 100%;
      margin-bottom: 10px;
    }
    .filters {
      margin-bottom: 0px;
    }
  }
}
