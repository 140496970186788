@import "../common/commonStyles.scss";

.chartTile {
  display: inline-block;
  height: 140px;
  width: 430px;
}
.chart,
.legends {
  display: inline-block;
  vertical-align: top;
}
.chart {
  left: 0px;
}
.legends {
  right: 0px;
  :global(.rv-discrete-color-legend-item) {
    padding: 4px;
    color: var(--neutralSecondary);
  }
}
.customTilesPane {
  display: flex;
  flex-basis: 100%;
  flex-direction: row;
  flex-wrap: wrap;
}
