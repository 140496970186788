@import "../../commonStyles.scss";

$padding: 10px 20px;

.root {
  @extend .contentBox;
  display: flex;
  flex-direction: column;
}
.root:focus {
  outline: none;
}
.header {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--neutralLight);
  overflow: hidden;
  text-align: left;
  min-height: 40px;
}
.header.compact {
  min-height: 30px;
}
.headerTaller {
  height: 65px;
}
.headerTopRow {
  height: 40px;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
}
.headerTopRow.compact {
  height: 30px;
  border-bottom-width: 0px;
}
.title {
  font-size: medium;
  flex: 1;
  color: var(--neutralPrimary);
  padding: 10px 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.title.compact {
  font-size: 14px;
  padding: 4px 12px;
}
.subtitle {
  color: var(--neutralPrimary);
  padding: 0px 12px;
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.menu {
  padding: $padding;
  text-align: right;
  overflow-y: hidden;
  overflow-x: auto;
}
.content {
  overflow: auto;
  max-height: 800px;
  position: relative;
}
.extraContent {
  padding: $padding;
}
.commandBar {
  :global(.ms-CommandBar) {
    background-color: var(--composeNeutralBackground);
    padding-right: 0px;
    padding-left: 0px;
    :global(.ms-Button) {
      background-color: var(--composeNeutralBackground);
    }
    :global(.ms-Button:hover) {
      background-color: var(--neutralLighterAlt);
    }
  }
}
.searchOnly {
  margin-right: 10px;
}
@media only screen and (max-width: $tinyScreenWidth) {
  .root {
    margin-right: 18x;
    margin-bottom: 18px;
  }
  .headerWithMenu {
    align-items: flex-start;
    flex-wrap: wrap;
  }
  .menu {
    order: 2;
    padding: 0px;
    flex: 1 1 100%;
  }
  .search {
    max-width: 150px;
  }
}
