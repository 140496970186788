$tinyScreenWidth: 540px;
$smallScreenWidth: 840px;
$mediumSmallScreenWidth: 1024px;
$mediumScreenWidth: 1380px;

.contentBox {
  background-color: var(--composeNeutralBackground);
  margin: 0px 20px 20px 0px;
  border: 1px solid var(--neutralQuaternaryAlt);
  box-shadow: 0 3px 10px var(--neutralQuaternary);
  overflow: hidden;
  position: relative;
}
.error {
  font-size: 14px;
  font-weight: 200;
  color: var(--neutralPrimary);
  white-space: normal;
  vertical-align: middle;
}
.error:before {
  font-family: "Garamond";
  content: "\26A0";
  font-size: 24px;
  font-weight: bold;
  color: var(--warning);
  padding-right: 6px;
  vertical-align: middle;
}
.badge {
  min-width: 16px;
  padding: 0px 7px;
  border-radius: 50%;
  font-size: 16px;
  text-align: center;
  color: #fff;
  margin-left: 4px;
}
.errorBadge {
  @extend.badge;
  background: var(--redDark);
}
.clickable:hover {
  cursor: pointer;
}
.tooltip {
  background-color: var(--neutralLight);
  color: var(--neutralPrimary);
  padding: 6px 10px;
  font-size: 12px;
  border-radius: 4px;
  box-shadow: 0 2px 4px var(--neutralSecondary);
  margin-right: 8px;
  margin-left: 8px;
  z-index: 100;
  .value {
    white-space: nowrap;
  }
}
