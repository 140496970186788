@import "../common/commonStyles.scss";

.root {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  border-left: 1px solid var(--neutralLight);
  .paneHeader {
    padding: 30px 20px;
    font-weight: 600;
    text-align: center;
  }
  .copilotContentRoot {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    .copilotContent {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      .chatPane {
        position: relative;
        flex-grow: 1;
      }
    }
    .debugConsolePane {
      position: relative;
      width: 300px;
      border-left: 1px solid var(--neutralLight);
      display: flex;
      flex-direction: column;
      .title {
        padding: 20px 10px;
        font-weight: 600;
        font-size: 16px;
      }
      .content {
        position: absolute;
        top: 60px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        flex-grow: 1;
        overflow-y: auto;
        overflow-x: hidden;
        margin-left: 10px;
        .message {
          font-size: 12px;
          font-weight: 300;
          padding-bottom: 10px;
          .timestamp {
            font-size: 10px;
            color: var(--themePrimary);
          }
        }
      }
    }
  }
  .commandBar {
    border-bottom: 1px solid var(--neutralQuaternary);
    box-shadow: 0 0 6px var(--neutralQuaternary);
    background-color: var(--neutralLight);
    :global(.ms-CommandBar) {
      background-color: var(--neutralLight);
      padding: 0px;
      :global(.ms-Button) {
        background-color: var(--neutralLight);
      }
      :global(.ms-Button:hover) {
        background-color: var(--neutralLighter);
      }
    }
  }
  .largeHeaderText {
    font-size: 24px;
  }
  .markdown {
    table,
    th,
    td {
      border: 1px solid var(--neutralLight);
      border-collapse: collapse;
    }
    table {
      margin-left: 4px;
    }
    th,
    td {
      padding: 4px 8px;
    }
    th {
      background-color: var(--neutralLighter);
    }
  }
}
.immersive {
  width: 1200px;
  padding: 6px 14px;
}
.dataTable {
  border: 1px solid var(--neutralLight);
  margin: 10px;
}
.tiles {
  margin-left: 10px;
  margin-top: 10px;
}
.queryContainer {
  border-width: 0px;
  border-radius: 6px;
  margin: 8px 10px;
  min-width: 300px;
}
.button {
  margin: 0px 20px 10px 10px;
}
.pageContent {
  overflow: auto;
  width: 1000px;
  height: 600px;
}
:global(.ms-ContextualMenu-linkContent) {
  font-size: 12px;
  max-width: 40vw;
}
