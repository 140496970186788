@import "../common/commonStyles.scss";

.root {
  margin-top: -28px;
  margin-right: 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
  .filters {
    display: inline-block;
    min-width: 300px;
  }
}
@media only screen and (max-width: $smallScreenWidth) {
  .root {
    margin-top: -10px;
    margin-right: 0px;
  }
}
