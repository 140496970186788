@import "../common/commonStyles.scss";

$padding: 14px;

.root {
  .field {
    display: inline-flexbox;
    font-size: 14px;
    padding: 0px $padding $padding 0px;
    .name {
      font-size: 12px;
      font-weight: 600;
      padding-bottom: 4px;
      color: var(--neutralPrimary);
    }
    .value {
      color: var(--neutralSecondary);
    }
  }
  .field:last-child {
    padding-bottom: 0px;
  }
}
