@keyframes dash {
  to {
    stroke-dashoffset: 50%;
  }
}
.line {
  stroke: var(--neutralTertiary);
  stroke-width: 2px;
}
.clickable:hover {
  .line {
    stroke: var(--black);
  }
}
.icon {
  background-color: var(--white);
  border-radius: 50%;
}
.foreignObject:focus {
  border: 7px solid black;
  width: 28px;
  height: 24px;
  overflow: visible;
}
.animation {
  animation-name: dash;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: reverse;
}
.valueText {
  font-size: 12px;
}
.valueText:hover {
  font-weight: 800;
  cursor: pointer;
}
