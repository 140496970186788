@import "./commonStyles.scss";

.root {
  padding: 20px;
  overflow: hidden;
  position: absolute;
  min-width: 460px;
  min-height: 240px;
  .title,
  .fieldsPane,
  .buttonsPane {
    position: absolute;
    left: 0px;
    right: 0px;
    padding: 20px;
  }
  .title {
    font-size: 16px;
    color: var(--neutralPrimary);
    top: 0px;
    height: 20px;
  }
  .fieldsPane {
    top: 60px;
    bottom: 70px;
    overflow: auto;
    padding: 0px 20px;
  }
  .buttonsPane {
    bottom: 0px;
    height: 30px;
    display: flex;
    justify-content: flex-end;
    .button {
      margin-left: 10px;
      height: 32px;
      min-width: 80px;
    }
  }
}
.modalRoot {
  width: 680px;
  height: 360px;
}
.error {
  margin-top: -20px;
  font-size: 12px;
}
.message {
  width: 100%;
}
.parameters {
  padding-top: 14px;
  display: table;
  font-size: 12px;
  .parameter {
    display: table-row;
    .prompt,
    .field,
    .parameterError {
      display: table-cell;
      padding: 4px;
      .error {
        margin-top: 0px;
      }
    }
    .prompt {
      white-space: nowrap;
    }
    .prompt::after {
      content: ": ";
      padding-right: 4px;
    }
  }
}
.textField,
.dateField {
  width: 500px;
  font-size: 12px;
}
.formError {
  margin-top: 10px;
  padding-bottom: 0px;
  font-size: 12px;
}

@media only screen and (max-width: $smallScreenWidth) {
  .root {
    .title {
      padding-bottom: 4px;
    }
  }
}
