@import "../../commonStyles.scss";

.tooltipbox {
  height: 12px;
  width: 12px;
  margin: 3px 5px 0px 0px;
}
.tooltipText {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
  line-height: 16px;
  vertical-align: middle;
}
.tooltipnumber {
  text-align: center;
  font-weight: 700;
  padding-top: 4px;
}
.flexstyle {
  display: flex;
  :global(.rv-xy-plot__series--label text) {
    fill: white;
  }
}
.legend {
  margin: auto;
}
