@import "../common/commonStyles.scss";

.root {
  margin-top: 10px;
}
.testSummary {
  padding: 10px;
  text-align: left;
  .fieldValue {
    font-weight: 600;
    padding-right: 30px;
  }
}
