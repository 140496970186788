@import "../common/commonStyles.scss";

.root {
  position: absolute;
  top: 0px;
  right: 36px;
  display: flex;
}

@media only screen and (max-width: $tinyScreenWidth) {
  .root {
    top: 24px;
  }
}
