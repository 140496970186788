.root {
  position: relative;
  align-items: center;
  text-align: center;
  padding: 10px;
  margin: auto;
  font-size: 12px;
  .content {
    display: inline-block;
    position: relative;
  }
  .selectionTips {
    position: absolute;
    display: inline-block;
    top: -10px;
    left: 10px;
    opacity: 0.8;
    color: var(--neutralSecondaryAlt);
  }
}
.path {
  fill: var(--neutralSecondaryAlt);
}
@media screen and (-ms-high-contrast: black-on-white) {
  .path {
    fill: black;
  }
}
@media screen and (-ms-high-contrast: white-on-black) {
  .path {
    fill: white;
  }
}
