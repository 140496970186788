@import "../common/commonStyles.scss";

$padding: 14px;

.root {
  .headingAtTop {
    padding-top: 0;
    padding-bottom: $padding;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 600;
  }
  .heading {
    padding-top: $padding;
    padding-bottom: $padding;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 600;
  }
  .why {
    border: 0.5px solid #d2d2d2;
    border-radius: 0.5rem;
    padding: 1rem 1rem 0 1rem;
    margin-top: 1rem;
    .title {
      font-size: 1rem;
      font-weight: 600;
    }
  }
  .field {
    display: inline-flexbox;
    font-size: 14px;
    padding: 0px $padding $padding 0px;
    .name {
      font-size: 12px;
      font-weight: 600;
      padding-bottom: 4px;
      color: var(--neutralPrimary);
    }
    .embeddedHtmlNoMargins {
      margin-block-start: 0 !important;
      margin-block-end: 0 !important;
      margin: 0 !important;
      p {
        margin: 0;
      }
    }
    .value {
      color: var(--neutralSecondary);
    }
    .valueWithTrailingSpace {
      color: var(--neutralSecondary);
    }
  }
  .field:last-child {
    padding-bottom: 0px;
  }
}
