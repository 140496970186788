.buttonContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.button:hover,
.buttonSelected {
  background-color: #0078d4;
  color: white;
}
.callout {
  max-width: 960px;
}
.label {
  font-size: 14px;
  font-weight: 600;
  padding: 5px 0px;
}
.buttonPane {
  display: flex;
  flex-direction: row;
}
.valueText {
  padding-top: 6px;
  padding-left: 4px;
}
