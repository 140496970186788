@import "./ConfigItemEdit.module.scss";
@import "./JsonEditor.module.scss";

.ConfigHistory {
  margin-left: 10px;
  margin-right: -10px;
  overflow-y: auto;
  height: 100%;
}
.ConfigHistoryPrint {
  overflow: unset;
}
.ConfigHistoryTile {
  margin-bottom: 20px;
}
.ConfigHistoryTilePrint {
  margin-bottom: 20px;
  border: none;
  box-shadow: none;
}
.modal {
  height: 80%;
  width: 80%;
}
.selectColumn {
  margin-left: 5px;
}
:global(.print .Tile_commandBar) {
  display: none;
}
:global(.print .Tile_header) {
  border-bottom: none;
}
