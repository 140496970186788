.root {
  display: flex;
  height: 100%;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  color: var(--themeSecondary);
  .prefixContent,
  .suffixContent {
    color: var(--neutralSecondary);
    font-size: 14px;
  }
  .valueContent {
    display: table;
  }
  .item {
    display: table-row;
    .category,
    .value {
      display: table-cell;
      text-align: left;
    }
    .category {
      padding-right: 10px;
    }
  }
}
