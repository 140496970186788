@import "../common/commonStyles.scss";

.modal :global(.ms-Modal-scrollableContent) {
  overflow-x: hidden;
}
.modalRoot {
  min-width: 90vw;
  min-height: 80vh;
}
.modalContent {
  overflow: hidden !important;
  padding: 0px !important;
}
.pivot {
  padding: 0px;
}
.pivotItem {
  padding: 0px;
}
.entityDocContent {
  width: 90vw;
  height: calc(80vh - 180px);
  padding: 0px;
  border: 1px solid var(--neutralLight);
  .spinner {
    padding-top: 20px;
  }
}
.editorLinePass,
.editorLineFail {
  width: 5px !important;
  margin-left: 5px;
}
.editorLinePass {
  background-color: green;
}
.editorInlinePass {
  background-color: #cfc;
}
.editorLineFail {
  background-color: red;
}
.editorInlineFail {
  background-color: #fcc;
}
.error {
  padding: 20px;
}
.button {
  width: 80px;
  height: 32px;
}
