@import "../common/commonStyles.scss";

.root {
  .remainingDaysPane {
    font-size: 16px;
    padding-bottom: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      font-weight: 600;
    }
    .remainingDays {
      padding: 6px;
      font-size: 36px;
      font-weight: 700;
    }
  }
}
.error {
  padding: 40px;
}
.healthDashboardRoot {
  text-align: left;
  height: 100%;
  overflow: auto;
}
.metricGroupFilters {
  padding-bottom: 10px;
  text-align: left;
}
.pivotItem {
  padding: 10px 0px;
  position: relative;
  text-align: center;
}
.pivotContent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  text-align: left;
}
.leftContent {
  padding-top: 34px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.rightContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.rightInnerContent {
  width: 100%;
}
.metricContents {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
}
.metricContent {
  padding-right: 20px;
  padding-bottom: 20px;
  position: relative;
}
.metricTableHeader {
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 10px;
}
.metricTable {
  display: table;
  box-shadow: 0 3px 10px #d0d0d0;
}
.teamHeaderRow,
.metricRow {
  display: table-row;
}
.teamHeader,
.teamHeaderEmpty,
.metricCell,
.metricType {
  display: table-cell;
  padding: 10px;
  text-align: center;
  vertical-align: middle;
  box-shadow: 1px 0 0 0 #d0d0d0, 0 1px 0 0 #d0d0d0, 1px 1px 0 0 #d0d0d0, 1px 0 0 0 #d0d0d0 inset,
    0 1px 0 0 #d0d0d0 inset;
}
.teamHeaderEmpty,
.teamHeader,
.metricType {
  font-weight: 600;
  font-size: 16px;
  background-color: white;
}
.metricType {
  text-align: left;
}
.metricCell {
  font-size: 14px;
  background: #f8f8f8;
  .content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    .visual {
      min-width: 48px;
    }
    .text {
      flex-grow: 1;
      text-align: center;
    }
    .textWithBadge {
      margin-left: 10px;
    }
    .textAsLink {
      text-decoration: underline;
      color: #0078d4;
    }
    .textAsLink:hover {
      color: black;
      font-weight: 500;
    }
  }
}

.metricTooltip {
  font-size: 16px;
  padding: 10px;
}
.tooltipRunOn {
  font-size: 12px;
}
.clickable:hover {
  cursor: pointer;
  background: linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
}
.spinner {
  padding: 20px;
}
.report {
  display: flex;
  justify-content: flex-start;
}
.trendTriangleIndicator {
  width: 0px;
  height: 0px;
  display: inline-block;
  margin-bottom: 2px;
  margin-left: 4px;
}
.trendSignalIndicator {
  width: 7px;
  height: 7px;
  display: inline-block;
}
.trendShapeCircle {
  border-radius: 5px;
}
.trendShapeSquare {
  border-radius: 0px;
}
.trendShapeTriangle {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 8px solid #999;
  width: 0px;
  height: 0px;
}
.trendTriangleUp {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid red;
}
.trendTriangleDown {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid green;
}
.trendArrowIndicator {
  position: relative;
  height: 5px;
  width: 5px;
}
.trendArrowIndicator > :global(.line1) {
  position: absolute;
  height: 10px;
  width: 10px;
}
.trendArrowIndicator > :global(.line2) {
  position: absolute;
  height: 5px;
  width: 5px;
}
.trendArrowUp > :global(.line1) {
  border-top: 2px solid gray;
  transform: rotate(-45deg);
  left: 6px;
  top: 2px;
}
.trendArrowUp > :global(.line2) {
  border-top: 2px solid gray;
  border-right: 2px solid gray;
  left: 5px;
}
.trendArrowDown > :global(.line1) {
  border-bottom: 2px solid gray;
  transform: rotate(-135deg);
  left: -1px;
}
.trendArrowDown > :global(.line2) {
  border-bottom: 2px solid gray;
  border-right: 2px solid gray;
  left: 5px;
}
.trendShapeFlat {
  height: 2px;
  width: 10px;
  background-color: #999;
}
.links {
  padding: 10px 10px 0px 10px;
  font-size: 16px;
  .link {
    padding: 0px 0px 4px 0px;
    text-decoration: underline;
  }
  .link:hover {
    font-weight: 500;
  }
  .icon {
    padding-left: 4px;
  }
}
.tooltipIcon {
  margin-left: 4px;
  font-size: 12px;
}
.tile {
  background-color: white;
  padding: 10px;
  box-shadow: 0 3px 10px #d0d0d0;
  width: 260px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.teamNote {
  padding: 10px 0px 0px 10px;
}
.tableSpacer {
  height: 20px;
}
