@import "../common/commonStyles.scss";

.itemStatusIcon {
  margin-right: 4px;
  margin-bottom: 1px;
  vertical-align: bottom;
}
.itemAction {
  padding: 0px 6px;
}
.nonIcmAdoLinkedItems {
  border-bottom: 1px solid var(--neutralLight);
}
