@import "./commonStyles.scss";

.root {
  position: relative;
  height: 100%;
  width: 100%;
  .contentPane,
  .copilotPane,
  .resizeBar {
    position: absolute;
    top: 0px;
    bottom: 0px;
  }
  .contentPane {
    left: 0px;
  }
  .copilotPane {
    left: 1600px;
    right: 0px;
  }
  .resizeBar {
    left: 1600px;
    width: 3px;
    background-color: transparent;
    z-index: 10;
  }
  .resizeBar:hover {
    cursor: col-resize;
  }
}
