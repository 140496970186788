.section {
  position: relative;
  background-color: #eee;
  border: 1px solid #ccc;
  padding: 10px 20px;
  margin-bottom: 20px;
  .sectionHeader {
    .sectionLabel {
      font-weight: 600;
      display: inline-block;
      width: 50px;
    }
    .sectionButton {
      margin-left: 20px;
      width: 150px;
    }
  }
  .diagram {
    margin-top: 10px;
    padding: 0px;
  }
}
.buttons {
  text-align: right;
  .button {
    margin-left: 10px;
  }
}
.panelContent {
  margin-left: -10px;
  margin-top: 10px;
}
