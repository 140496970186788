@import "../common/commonStyles.scss";

.contentRoot {
  width: 90vw;
  height: 90vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  .closeIcon {
    display: inline;
    position: absolute;
    top: 20px;
    right: 20px;
    color: black;
    border: 1px solid transparent;
    border-radius: 4px;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    vertical-align: middle;
  }
  .closeIcon:hover {
    background-color: var(--themeLight);
    cursor: pointer;
  }
  .title {
    position: relative;
    padding: 20px;
    padding-right: 60px;
    font-size: 24px;
    background-color: var(--neutralLighter);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .actions {
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    .searchTypeDropdown {
      width: 160px;
    }
    .statusDropdown {
      width: 80px;
    }
    .searchTypeDropdown,
    .statusDropdown,
    .dateFilter {
      margin-right: 20px;
    }
    .searchButton {
      margin-top: 28px;
    }
    .commandBarPane {
      flex-grow: 1;
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  }
  .content {
    flex-grow: 1;
    overflow: hidden;
    .error,
    .spinner,
    .info {
      padding: 20px;
    }
    .table {
      margin-top: 10px;
      margin-left: 20px;
      height: 95%;
    }
  }
}
