@import "../common/commonStyles.scss";

.message {
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  b {
    font-weight: 500;
  }
}
.root {
  position: relative;
  padding: 0px 20px 20px 20px;
  .metrics,
  .entityTiles,
  .field {
    padding: 6px 0px;
    font-size: 12px;
    font-weight: 500;
    .fieldLabel {
      padding-bottom: 2px;
      font-size: 11px;
      font-weight: 400;
      color: var(--neutralSecondary);
    }
    .fieldValue {
      overflow-wrap: anywhere;
    }
    .metric {
      display: table-row;
    }
    .itemAction,
    .metricLabel,
    .metricValue {
      display: table-cell;
      vertical-align: middle;
    }
    .metricLabel,
    .metricValue {
      padding-right: 10px;
    }
    .metricLabel {
      font-size: 11px;
      color: var(--neutralSecondary);
    }
    .metricLabel:after {
      content: ":";
    }
  }
  .metrics,
  .entityTiles {
    .fieldLabel {
      font-size: 12px;
    }
  }
  .entityTiles {
    .fieldLabel {
      padding-bottom: 8px;
    }
  }
  :global(.ms-CommandBar) {
    padding: 0px;
    margin-bottom: 4px;
  }
  :global(.ms-CommandBar),
  :global(.ms-Button) {
    background-color: var(--neutralLighter);
  }
  :global(.ms-Button:hover) {
    background-color: var(--neutralLight);
  }
}
.spinner {
  padding-top: 40px;
}
.entityMetric {
  padding-bottom: 20px;
}
.entityTests {
  margin-top: -20px;
}
.entityTitle {
  font-size: 16px;
  font-weight: 500;
  padding: 6px 0px;
  color: var(--neutralSecondary);
}
.metricItem {
  padding-top: 8px;
}
.metricTitle {
  font-size: 12px;
  font-weight: 300;
}
.metricChart {
  width: 100%;
  height: 12px;
}
.metricBar {
  height: 12px;
  fill: #aaa;
}
.completedInBar,
.passBar {
  fill: green;
}
.completedOutBar {
  fill: #8e6b12;
}
.pendingOutBar,
.failBar {
  fill: #e50000;
}
.showAllMetricsButton {
  font-size: 11px;
  margin-top: 4px;
  margin-bottom: 10px;
  padding: 0px 10px;
}
@media only screen and (max-width: $smallScreenWidth) {
  .root {
    padding: 10px;
  }
}
