html,
body,
main,
#root {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  --neutralDarkest: #000;
  --neutralDark: #212121;
  --neutralPrimary: #333333;
  --neutralPrimaryAlt: #3c3c3c;
  --neutralSecondary: #666666;
  --neutralSecondaryAlt: #767676;
  --neutralTertiary: #a6a6a6;
  --neutralTertiaryAlt: #c8c8c8;
  --neutralQuaternary: #d0d0d0;
  --neutralQuaternaryAlt: #dadada;
  --neutralLight: #eaeaea;
  --neutralLighter: #f4f4f4;
  --neutralLighterAlt: #f8f8f8;
  --neutralLightest: #fff;
  --redDark: #a80000;
  --flaggedMessage: #fffdd9;
  --richUserContentBackground: #fff;
  --composeNeutralBackground: #fff;
  --composeNeutralLighterBackground: #f4f4f4;
  --themeDarker: #004578;
  --themeDark: #005a9e;
  --themeDarkAlt: #106ebe;
  --themePrimary: #0078d4;
  --themePrimaryDarker: #0072c8;
  --themeSecondary: #2b88d8;
  --themeTertiary: #71afe5;
  --themeLight: #c7e0f4;
  --themeLighter: #deecf9;
  --themeLighterAlt: #eff6fc;
  --success: darkgreen;
  --warning: #8e6b12;
}
h1,
h2,
h3 {
  font-family: SegoeUI-SemiBold, Helvetica, Arial, sans-serif;
  color: var(--neutralPrimary);
  padding-top: 20px;
  font-weight: 400;
}
h1 {
  flex-grow: 1;
  font-size: 24px;
  line-height: 36px;
}
h2 {
  font-size: 20px;
}
h3 {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto,
    "Helvetica Neue", sans-serif;
  font-size: 18px;
  line-height: normal;
}
.spinner {
  padding-top: 40px;
}
a {
  text-decoration: none;
  color: var(--themeDark);
}
a:hover {
  text-decoration: underline;
  cursor: pointer;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.ms-List-cell:nth-child(odd) .ms-DetailsRow {
  background: var(--neutralLighterAlt);
}
.ms-List-cell .ms-DetailsRow:hover {
  background: var(--themeLighterAlt);
}
