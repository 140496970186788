@import "../../commonStyles.scss";

.root {
  .content {
    background-color: var(--neutralLighter);
    padding: 20px 0px 0px 20px;
    max-height: auto;
  }
}
.groupingTileContent {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}
