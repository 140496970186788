@import "../common/commonStyles.scss";

.chartMenuContent {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.chartDisplayToggle {
  margin-top: 10px;
  padding: 0px 20px;
  :global(.ms-Toggle-label) {
    margin-top: 3px;
    margin-right: 8px;
  }
  :global(.ms-Label) {
    padding-top: 1px;
    padding-left: 0px;
    margin-left: 6px;
    font-size: 12px;
  }
}
.message {
  color: var(--neutralPrimary);
}
.content {
  max-height: 10000px;
}
.definitionTileContent {
  padding: 12px;
  font-size: 12px;
}
.itemAction {
  padding: 0px;
  width: 22px;
  height: 22px;
}

@media only screen and (max-width: $tinyScreenWidth) {
  .chartDisplayToggle {
    display: none;
  }
}
