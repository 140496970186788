@import "../common/management.common.scss";
@import "../../../app/LeftNav.module.scss";

.pivotItemContainer {
  position: absolute;
  top: 60px;
  bottom: 20px;
  left: 0px;
  right: 0px;
}
.tableForValidationResults {
  padding-top: 20px;
}
.tableStatus {
  height: "100%";
  display: "block";
}
.tableError {
  @extend .tableStatus;
  color: var(--redDark);
}
.tablePass {
  @extend .tableStatus;
  color: var(--success);
}
.tableWarning {
  @extend .tableStatus;
  color: var(--warning);
}
.hints {
  padding-left: 5px;
  vertical-align: text-bottom;
  padding-bottom: 1px;
  color: var(--themePrimary);
}
.hints:hover {
  cursor: pointer;
  color: var(--neutralDark);
}
