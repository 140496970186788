@import "../common/commonStyles.scss";

.root {
  margin-top: -26px;
  margin-bottom: 20px;
  margin-right: 20px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: flex-end;
  .dropdown {
    margin-left: 10px;
    width: 300px;
  }
  .filter {
    margin-left: 10px;
    .toggle {
      margin-top: 6px;
      margin-left: 10px;
    }
  }
  .search {
    width: 200px;
  }
}
@media only screen and (max-width: $smallScreenWidth) {
  .root {
    margin-top: -10px;
    flex-wrap: wrap;
  }
}
