@import "../common/commonStyles.scss";

.appModuleContent {
  padding-right: 40px;
}
.copilotPane {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
}
