@import "../common/commonStyles.scss";

.root {
  clear: both;
  display: flex;
  .diagram {
    zoom: 0.75;
    font-size: 16px;
    .content {
      .node {
        // background-color: grey;
      }
      .line {
        stroke: var(--neutralSecondaryAlt);
      }
      .clickable:hover {
        // background-color: red;
        .line {
          // stroke: red;
        }
      }
    }
  }
  .diagramTile {
    height: 100%;
  }
  .diagramTile:first-child {
    width: 49%;
  }
  .diagramTile:last-child {
    flex-grow: 1;
    flex: 1;
  }
}
@media screen and (-ms-high-contrast: black-on-white) {
  .root {
    .diagram {
      .content {
        .node {
          border: 1px solid black;
        }
        .line {
          stroke: black;
        }
      }
    }
  }
}
@media screen and (-ms-high-contrast: white-on-black) {
  .root {
    .diagram {
      .content {
        .node {
          border: 1px solid white;
        }
        .line {
          stroke: white;
        }
      }
    }
  }
}
@media only screen and (max-width: $mediumScreenWidth) {
  .root {
    display: block;
    .diagramTile:first-child,
    .diagramTile:last-child {
      width: auto;
    }
  }
}
@media only screen and (max-width: $smallScreenWidth) {
  .root {
    .diagram {
      zoom: 0.6;
    }
  }
}
