@import "../../commonStyles.scss";

.root {
  font-size: 12px;
  color: var(--neutralPrimary);
  white-space: nowrap;
  .label {
    padding-right: 2px;
    color: var(--neutralSecondary);
  }
  .labelTop {
    color: var(--neutralPrimary);
    padding-bottom: 15px;
    font-size: 14px;
    font-weight: 400;
  }
  .value,
  .selectedValue {
    padding: 2px 6px;
  }
  .value:hover {
    cursor: pointer;
    text-decoration: underline;
    color: var(--themeDark);
  }
  .selectedValue {
    font-weight: 600;
    background-color: var(--themeDark);
    color: var(--neutralLighterAlt);
    border-radius: 4px;
  }
}
