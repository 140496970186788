.root {
  position: relative;
  display: inline-block;
  .valueText,
  .topLabelText,
  .bottomLabelText {
    font-size: 16px;
    font-weight: 600;
    padding: 0px;
    text-align: center;
  }
  .minMaxValueText {
    font-size: 11px;
    position: absolute;
    display: inline-block;
    text-align: center;
  }
  .tooltipIcon {
    margin-left: 4px;
    font-size: 12px;
  }
  .tooltip {
    font-size: 16px;
    padding: 10px;
  }
}
