@import "../../../appModules/common/commonStyles.scss";

.error {
  padding-bottom: 20px;
}
.message {
  padding: 12px;
}
.detailsList {
  position: absolute;
  height: 100%;
  width: 100%;
}
