@import "../common/commonStyles.scss";

.appModuleContent {
  :global(.ms-DetailsRow-cell) {
    max-height: 200px;
    overflow-y: auto;
  }
}
.statusText {
  padding-left: 6px;
}
.statusIcon {
  line-height: 16px;
  vertical-align: top;
}
.colorLabel {
  padding: 3px 6px;
  border-radius: 3px;
  color: white;
  text-align: center;
  white-space: break-spaces;
}
.description {
  font-size: 13px;
  margin-top: -10px;
  margin-bottom: 20px;
  margin-right: 20px;
}
