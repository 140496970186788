.root {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
}
.dataField {
  display: inline-block;
  min-width: 60px;
  padding: 10px;
  vertical-align: top;
  .fieldLabel {
    color: var(--neutralPrimary);
    font-size: 12px;
    font-weight: 400;
    padding-bottom: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .displayOnly {
    line-height: 30px;
    vertical-align: middle;
  }
  .checkbox {
    margin-top: 5px;
  }
  .hintsIcon,
  .editorIcon {
    padding-left: 5px;
    color: var(--neutralTertiary);
    vertical-align: text-bottom;
    padding-bottom: 1px;
  }
  .hintsIcon {
    color: var(--themePrimary);
  }
  .required:after {
    content: "*";
    color: red;
    padding-left: 2px;
  }
  .datePicker {
    :global(.ms-TextField-field) {
      padding-right: 36px;
    }
  }
}
.subTitle {
  color: var(--neutralSecondary);
  font-size: 12px;
  font-weight: 300;
  padding-bottom: 4px;
  margin-top: -10px;
}
.fieldError {
  padding-top: 10px;
  font-size: 12px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  color: var(--redDark);
}
.dataField .containerRoot {
  margin-top: 10px;
}
.dataField .containerRoot.compact {
  margin-top: 0px;
}
.dataField:first-child .containerRoot {
  margin-top: 0px;
}
.containerRoot {
  .containerLabel {
    color: var(--neutralSecondary);
    margin-bottom: 0px;
    padding-bottom: 6px;
    display: flex;
    align-items: center;
  }
  .collapsedIcon,
  .displayViewIcon {
    padding-left: 10px;
    color: var(--neutralTertiary);
  }
  .collapsedIcon:hover,
  .displayViewIcon:hover {
    cursor: pointer;
    color: var(--neutralDark);
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    transition: opacity 0.4s, max-height 0.4s, height 0.4s;
  }
  .fields.content {
    margin: -6px -10px;
  }
  .containerSearch {
    margin-left: 20px;
    margin-top: 4px;
  }
  .noItemText {
    padding: 10px 0px;
    font-size: smaller;
  }
}
.containerRoot.compact {
  .containerLabel {
    font-size: 14px;
    padding-bottom: 0px;
  }
  .itemLabelField {
    padding-bottom: 0px;
  }
}
.lastInRow {
  page-break-after: always;
  break-after: always;
}
.lastInRowField {
  flex: 1 1 auto;
  width: 100%;
  height: 1px;
}
.doNotExpandWidth {
  flex: none;
}
.contextField {
  padding: 0px;
}
//ItemContainer
.addItemIcon {
  color: var(--neutralTertiary);
  padding-left: 10px;
}
.addItemIcon:hover {
  cursor: pointer;
  color: var(--neutralDark);
}
.items {
  border: 1px solid var(--neutralTertiary);
  margin-bottom: 6px;
  transition: opacity 0.4s, max-height 0.4s, height 0.4s;
}
.fields {
  display: flex;
  flex-wrap: wrap;
}
.itemFields {
  padding: 6px 4px;
  flex-direction: row;
  flex-wrap: nowrap;
  border-left: 4px solid transparent;
}
.itemFields:not(:last-child) {
  border-bottom: 1px solid var(--neutralQuaternaryAlt);
}
.itemFields:nth-child(odd) {
  background-color: var(--neutralLight);
}
.itemFields:nth-child(even) {
  background-color: var(--neutralLighter);
}
.itemFields:hover {
  border-left-color: var(--themePrimary);
}
.pivotItem {
  margin-top: 4px;
}
.itemLabelField {
  padding-bottom: 6px;
}
.itemLabel,
.itemActions {
  display: inline-flex;
  align-items: flex-end;
}
.itemLabel {
  padding: 2px 6px;
  font-size: 14px;
  background-color: var(--neutralSecondaryAlt);
  color: var(--neutralLightest);
}
.itemActions {
  padding-left: 8px;
  font-size: 12px;
}
.actionIcon {
  padding-left: 6px;
  color: var(--neutralTertiary);
}
.hintsIcon:hover,
.actionIcon:hover,
.editorIcon:hover {
  cursor: pointer;
  color: var(--neutralDark);
}
.editorModal {
}
.dialogContent {
  height: 100%;
}
.dateText {
  position: relative;
  :global(.ms-TextField-fieldGroup) {
    width: 110px;
  }
  :global(.ms-TextField-field) {
    display: none;
  }
  input.text {
    position: absolute;
    border-width: 0px;
    top: 6px;
    left: 6px;
    width: 70px;
    padding: 4px 2px;
  }
}
