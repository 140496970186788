.reprocessFinancialEntities {
  padding-right: 20px;

  .reprocessFinancialEntitiesQuery {
    padding-left: 0px;
    padding-right: 0px;
  }

  .flags {
    margin-bottom: 20px;
    > * {
      margin-top: 10px;
    }
  }

  .runQueryButton {
    margin-bottom: 20px;
  }

  .reprocessFinancialEntitiesResults {
    margin-right: 0px;
  }
}
