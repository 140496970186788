@import "../common/commonStyles.scss";

$supplimentaryPaneHeight: 200px;
$detailPaneWidth: 320px;
$contentTop: 130px;

.content {
  position: absolute;
  top: $contentTop;
  bottom: 0px;
  left: 0px;
  right: 0px;

  .mainContentPane,
  .detailPane {
    position: absolute;
    top: 0px;
    bottom: 0px;
    overflow: hidden;
  }
  .mainContentPane {
    left: 0px;
    right: $detailPaneWidth;
    display: flex;
    flex-direction: column;
    align-content: stretch;
    overflow: hidden;
    overflow-y: scroll;
    transition: right 0.4s;
    .supplimentaryPane {
      padding: 20px 36px;
    }
    .descPane {
      padding: 0px 36px 8px 36px;
      font-size: 14px;
    }
    .searchPane {
      padding: 0px 16px 0px 36px;
    }
    .diagramPane {
      display: flex;
      justify-content: center;
      align-content: center;
      position: relative;
    }
    .supplimentaryPane {
      flex: 1;
      padding-top: 10px;
      .pivotItem {
        padding: 0px;
        height: 100%;
        overflow: auto;
        border: 1px solid var(--neutralQuaternaryAlt);
        box-shadow: 0 3px 10px var(--neutralQuaternary);
      }
      .message {
        padding: 20px;
      }
    }
    .selectionHistory {
      padding: 20px 0px 0px 20px;
      flex: 30%;
      .entities {
        font-size: 12px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
      .entity {
        margin-right: 20px;
        margin-bottom: 20px;
        padding: 2px 10px;
        background-color: #777;
        color: white;
        border-radius: 4px;
        white-space: nowrap;
        max-width: 200px;
      }
    }
  }
  .detailPane {
    right: 0px;
    width: $detailPaneWidth;
    border: 1px solid var(--neutralQuaternaryAlt);
    box-shadow: 0 3px 10px var(--neutralQuaternary);
    overflow-y: auto;
    transition: width 0.4s, opacity 0.4s;
  }
}
.backButton {
  position: absolute;
  left: 36px;
  top: -8px;
}
.backButton:hover {
  background-color: var(--neutralLight);
}
.collapseToggleIcon {
  right: 0px;
}
.itemAction {
  height: auto;
}
.itemAction :global(.ms-Button-icon:hover) {
  font-weight: 600;
  color: var(--themePrimary);
}
.error {
  padding: 20px;
}
.spinner {
  padding-top: 40px;
}
.queryTimestamp {
  position: absolute;
  top: 16px;
  left: 36px;
  font-size: 12px;
  color: var(--neutralPrimary);
  .queryTime {
    padding-left: 20px;
  }
  .time {
    padding-left: 6px;
    font-weight: 500;
    color: var(--themeDark);
  }
}
.relationsTile {
  margin: 0px;
}
@media only screen and (max-width: $mediumSmallScreenWidth) {
  .content {
    .mainContentPane,
    .detailPane {
      position: relative;
    }
    .mainContentPane {
      width: 100%;
      .searchPane,
      .diagramPane,
      .supplimentaryPane {
        padding: 20px;
      }
    }
    .detailPane {
      margin: 20px;
      width: auto;
    }
  }
}

@media only screen and (max-width: $smallScreenWidth) {
  .content {
    .mainContentPane {
      .diagramPane {
        zoom: 0.7;
      }
    }
  }
}
