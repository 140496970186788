@import "./commonStyles.scss";

.reportContainer {
  margin-top: 20px;
  border: 1px solid var(--neutralLight);
  overflow: hidden;
  iframe {
    border-width: 0px;
    min-height: 1024px;
  }
}
