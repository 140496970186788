@import "../shared/commonStyles.scss";

.root {
  text-align: center;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto,
    "Helvetica Neue", sans-serif;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.leftNav,
.appModule,
.paneResizer,
.paneResizerCollapsed {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow: hidden;
  background-color: var(--neutralLighter);
}
.body {
  position: relative;
  overflow: hidden;
  background-color: var(--neutralLighter);
  flex-grow: 1;
}
.leftNav {
  right: auto;
  background-color: var(--neutralLight);
  overflow-y: auto;
  padding: 0px;
}
.paneResizer,
.paneResizerCollapsed {
  left: auto;
  right: auto;
  border-left: 1px solid var(--neutralQuaternary);
}
.paneResizer:hover,
.paneResizerDragging {
  cursor: col-resize !important;
  border-left-color: var(--neutralTertiary);
}
.appModule {
  left: auto;
  padding: 0px;
}
