@import "../../commonStyles.scss";

.root,
.contact {
  display: inline-block;
  margin-left: 0px;
  margin-right: 0px;
}
.contact:not(:first-child):before {
  content: ", ";
}
.contact:focus {
  border: 1px dotted #777;
}
