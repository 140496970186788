@import "../common/commonStyles.scss";

.header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 14px;
  .headerToggle,
  .headerTitle,
  .headerFields {
  }
  .headerToggle {
    width: 20px;
    font-size: 12px;
    height: 20px;
    margin-left: -10px;
    width: 30px;
  }
  .headerToggle:hover,
  .headerAction:hover {
    cursor: pointer;
    color: var(--themePrimary);
  }
  .headerToggleIcon {
    font-size: 12px;
    height: 100%;
    padding-top: 4px;
  }
  .headerTitle {
    min-width: 400px;
  }
  .headerFields {
    width: 120px;
    padding-left: 20px;
  }
  .headerField {
    padding-left: 20px;
  }
  .headerId {
    padding: 2px 10px;
    font-size: 12px;
    width: 60px;
  }
  .headerActions {
    padding-left: 20px;
    text-align: right;
    flex: 1;
    white-space: nowrap;
    .headerAction {
      margin-left: 2px;
    }
  }
}
.criteriaTileContent {
  padding: 12px;
  font-size: 12px;
}
