@import "../../commonStyles.scss";

.root {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: var(--white);
  zoom: 1.4;
  max-width: none;
  max-height: none;
  border-radius: 0px;
  .header,
  .content {
    position: absolute;
    right: 0px;
    left: 0px;
  }
  .header {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    top: 0px;
    height: 80px;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    color: var(--neutralSecondary);
  }
  .title {
    padding: 0px 40px;
  }
  .subtitle {
    font-size: 16px;
    font-weight: 400;
  }
  .close {
    position: absolute;
    right: 20px;
    top: 10px;
    color: var(--neutralTertiary);
  }
  .close:hover {
    background-color: var(--neutralLighter);
    cursor: pointer;
  }
  .content {
    top: 80px;
    bottom: 0px;
    width: 100%;
    overflow: auto;
  }
  .contentInner {
    width: 100%;
    :global(.ms-Viewport),
    :global(.TileFullScreen_content) {
      width: 100%;
      height: 100%;
    }
    :global(.Visual_root) {
      display: flex;
      justify-content: center;
    }
  }
}
@media only screen and (max-width: $smallScreenWidth) {
  .root {
    zoom: 90%;
  }
}
