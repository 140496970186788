@import "../../commonStyles.scss";

.root {
  position: relative;
  .content {
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    overflow: hidden;
    overflow-y: auto;
  }
  .bottomContent {
    position: absolute;
    right: 0px;
    left: 0px;
    bottom: 0px;
  }
}
.svg {
  width: 100%;
  height: 100%;
  display: block;
}
.xAxis,
.yAxis {
  stroke-width: 1px;
  stroke: var(--neutralQuaternaryAlt);
}
.tickLine {
  stroke-width: 1px;
  stroke: var(--neutralQuaternaryAlt);
}
.timelineRect,
.markerRect {
  fill: var(--themePrimary);
  opacity: 0.8;
}
.timelineRect:hover,
.markerRect:hover {
  cursor: pointer;
  opacity: 1.2;
}
.dateLabel,
.timelineLabel,
.groupLabel {
  color: var(--neutralSecondary);
  fill: var(--neutralSecondary);
}
.groupLabel {
  text-anchor: end;
}
.timelineLabel {
  color: var(--themeDark);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
}
.timelineLabel:hover {
  font-weight: 600;
  cursor: pointer;
  text-decoration: underline;
}
.marker {
  stroke-width: 0px;
  fill-opacity: 0.6;
}
.marker:hover {
  opacity: 1;
  cursor: pointer;
  zoom: 2;
}
.moreMarker {
  opacity: 0.5;
}
.lastMarkerRect {
  opacity: 1;
}
