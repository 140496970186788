@import "../common/commonStyles.scss";

.root {
}
.idField,
.versionField,
.typeDropdown,
.dateTypeDropdown,
.dateField,
.dateText,
.searchButton,
.filterButtonContainer,
.topNTextbox {
  margin-right: 10px;
  margin-bottom: 10px;
  white-space: nowrap;
  min-width: 80px;
}
.typeDropdown {
  width: 260px;
}
.dateTypeDropdown {
  width: 220px;
  min-width: 220px;
}
.idField {
  width: 320px;
}
.versionField {
  width: 120px;
}
.randomTopToggle {
  margin-right: 10px;
  margin-bottom: 22px;
}
.advancedOptionsIcon {
  margin-right: 10px;
  margin-bottom: 18px;
}
.advancedOptionsIcon:hover {
  cursor: pointer;
  font-weight: 800;
}
.filterButtonRoot {
  border: 1px solid var(--neutralDarkest);
  height: 30px;
  line-height: 30px;
  white-space: nowrap;
  width: fit-content;
}
.filterButton {
  margin-right: 2px;
  height: 30px;
  width: 30px;
}
.filterButtonLabel {
  vertical-align: top;
  color: var(--neutralPrimary);
  font-size: 14px;
  font-weight: 600;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto,
    "Helvetica Neue", sans-serif;
  padding: 5px 0px;
  display: inline-block;
}
.dateText {
  vertical-align: baseline;
  line-height: 32px;
  min-width: auto;
}
.pivot {
  margin: 0px;
}
.searchInputs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0px 20px;
  margin-top: -10px;
  background-color: var(--composeNeutralBackground);
  align-items: flex-end;
}
.searchFilterModal {
  .content {
    width: 640px;
    display: flex;
    flex-direction: row;
    .field {
      margin-right: 20px;
    }
    .fieldDropdown {
      width: 320px;
    }
    .operatorDropdown {
      width: 180px;
    }
    .valueField {
      min-width: 200px;
      margin-right: 0px;
    }
  }
  .error {
    padding-top: 10px;
  }
}
.filterList {
  display: inline-block;
  font-size: 12px;
  padding: 6px 20px 0px 20px;
  .filter {
    display: inline-block;
    margin: 2px 6px 6px 0px;
    padding: 6px 10px;
    background-color: var(--themeLighter);
    border-radius: 12px;
    vertical-align: middle;
    position: relative;
  }
  .filter:hover {
    cursor: pointer;
    background-color: var(--themeLight);
  }
  .filter.selected {
    background-color: var(--themeDarker);
    color: var(--neutralLightest);
    .filterIcon {
      color: var(--neutralLight);
    }
  }
  .logicalOperator {
    padding-right: 6px;
    vertical-align: baseline;
  }
  .groupOperator {
    padding-right: 6px;
    font-size: 20px;
  }
  .filterIcon {
    font-size: 16px;
    padding-left: 6px;
    vertical-align: bottom;
    color: var(--neutralSecondaryAlt);
  }
}
.topNTextbox {
  width: 80px;
}

@media only screen and (max-width: $smallScreenWidth) {
  .searchInputs :global(.ms-Dropdown-container) {
    width: 100%;
  }
  .searchFilterModal {
    padding-bottom: 20px;
    .content {
      width: auto;
      display: grid;
      flex-direction: row;
      .field {
        margin-left: 0px;
        margin-right: 10px;
        width: 100%;
      }
    }
  }
}
