@import "../common/commonStyles.scss";

.contentRoot {
  display: flex;
  .timeline,
  .timelineTable {
    display: inline-block;
    white-space: nowrap;
    width: 50%;
  }
  .timeline {
    padding: 10px 20px 4px 20px;
  }
  .timelineTable {
    border-left: 1px solid var(--neutralLight);
    :global(.ms-List-cell:hover) {
      cursor: default;
    }
  }
}

@media only screen and (max-width: $smallScreenWidth) {
  .contentRoot {
    display: block;

    .timeline,
    .timelineTable {
      display: block;
      width: auto;
      margin: 0px;
      border-width: 0px;
    }
    .timeline {
      padding: 10px;
    }
    .timelineTable {
      padding: 0px;
      border-top: 1px solid var(--neutralLight);
    }
  }
}
