@import "../common/commonStyles.scss";

@keyframes :global(nodeBorderBlink) {
  0% {
    border-color: #df8fcf;
  }
  50% {
    border-color: #a966a2;
  }
  100% {
    border-color: #df8fcf;
  }
}
.container {
  margin: 40px 36px 0px 36px;
  overflow: auto;
  width: 100%;
  display: flex;
  align-content: center;
}
.spinner {
  padding-top: 40px;
  align-self: flex-start;
  -webkit-align-self: flex-start;
}
.error,
.message {
  padding: 36px;
  align-self: flex-start;
  justify-self: flex-start;
  -webkit-align-self: flex-start;
  -webkit-justify-self: flex-start;
}

@media only screen and (max-width: $tinyScreenWidth) {
  .container {
    margin-top: 50px;
  }
}
