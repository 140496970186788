@import "../common/commonStyles.scss";

.content {
  text-align: left;
  margin-right: 20px;
}
.topArea {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .actions {
    display: flex;
    flex-direction: row;
    .toggle {
      padding-left: 20px;
    }
  }
}
.table {
  width: 100%;
  box-shadow: 0 3px 10px #d0d0d0;
  th,
  td {
    min-width: 200px;
    box-shadow: 1px 0 0 0 #d0d0d0, 0 1px 0 0 #d0d0d0, 1px 1px 0 0 #d0d0d0, 1px 0 0 0 #d0d0d0 inset,
      0 1px 0 0 #d0d0d0 inset;
  }
  th {
    background-color: var(--neutralSecondary);
    color: var(--white);
  }
  th,
  td.type,
  td.subType,
  .controls {
    padding: 6px 10px;
  }
  td.type {
    background-color: var(--themeLight);
  }
  td.subType {
    background-color: var(--themeLighter);
  }
  td.controlsCell {
    position: relative;
    vertical-align: top;
    background-color: var(--white);
    .control {
      padding: 2px 0px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
    }
    .soxTag {
      max-height: 12px;
      display: inline-block;
      font-size: 8px;
      border-radius: 4px;
      padding: 1px 3px;
      margin-top: 6px;
      margin-right: 4px;
      margin-bottom: 2px;
      background-color: var(--themeLight);
      color: var(--themeDarkest);
      vertical-align: middle;
    }
    .linkIcon {
      padding-left: 4px;
    }
    .adoLink {
      margin-top: 5px;
      margin-left: 6px;
      font-size: 10px;
    }
  }
  .status {
    min-width: 10px;
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 10px;
    margin-right: 6px;
    margin-top: 8px;
  }
}
.legends {
  padding-bottom: 10px;
  white-space: nowrap;
  .dot {
    display: inline-block;
    margin: 4px 4px 0px 10px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
  }
}
.onboardingStatus {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: smaller;
  padding: 8px;
  margin: 0px 1px;
  background-color: #eee;
  .leftPane {
    flex-basis: 50%;
    text-align: right;
  }
  .rightPane {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    flex-basis: 50%;
    text-align: left;
    padding: 0px 4px;
  }
  .percentText {
    color: var(--themePrimary);
    font-weight: 700;
  }
  .onboardIndicator {
    position: absolute;
    height: 16px;
    width: 4px;
    background-color: var(--themeSecondary);
  }
}
