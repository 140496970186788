.root {
  font-size: 12px;
  font-weight: 200;
  h1 {
    padding: 0px;
    margin: 0px;
    font-size: 14px;
    font-weight: 400;
  }
  .query {
    margin-bottom: 20px;
  }
  .testResult {
    font-weight: 300;
    overflow: auto;
  }
  .dataForm {
    margin-left: -10px;
  }
}
.modalRoot {
  width: 80vw;
  height: 80vh;
}
