@import "../common/commonStyles.scss";

.appModuleContent {
  color: var(--neutralPrimary);
  padding: 0px;
  font-size: 14px;
  .contentSection,
  .headerContent {
    display: flex;
    justify-content: center;
  }
  .headerContent {
    height: 200px;
    background-color: #040a22;
    background-image: url(../../images//RadarFull.jpg);
    background-repeat: no-repeat;
    background-size: auto 360px;
    background-position-x: right;
    background-attachment: fixed;
    .headerPane {
      color: white;
      height: 200px;
      padding: 40px 0px;
      .title {
        font-size: 36px;
        font-weight: 100;
      }
      .subTitle {
        font-weight: 600;
        padding-top: 10px;
      }
      .headerText {
        font-weight: 100;
        padding-top: 10px;
        width: 300px;
      }
    }
  }
  .contentSection {
    background: var(--neutralLightest);
  }
  .subjectContentPane {
    display: flex;
    .subjectPane {
      width: 25%;
      padding: 20px 0px 20px 10px;
      margin: 0px;
      margin-right: -10px;
      .subjectImage {
        height: 65px;
        background: white;
      }
      .subjectTitle {
        font-size: 20px;
        font-weight: 600;
        padding-top: 10px;
        padding-bottom: 6px;
      }
      .subjectText {
        padding-right: 28px;
      }
    }
    .arrowPane {
      height: 100%;
      width: 80px;
      position: relative;
      .arrowImage {
        height: 120px;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }
  .systemContentSection,
  .sectionTitle {
    background-color: var(--neutralLight);
  }
  .systemContentSection {
    padding: 20px 0px 0px 0px;
  }
  .systemContentPane {
    display: flex;
    justify-content: space-evenly;
    .systemPane {
      width: 200px;
      min-height: 140px;
      padding: 20px 20px 50px 20px;
      text-align: center;
      @extend .contentBox;
    }
    .systemTitle {
      font-size: 16px;
      font-weight: 600;
      padding-bottom: 20px;
    }
    .systemText {
      text-align: left;
      text-justify: distribute-all-lines;
      color: var(--neutralSecondary);
    }
  }
  .whatsNewSection {
    position: relative;
    display: flex;
    justify-content: center;
    color: var(--neutralLightest);
    .firstCell,
    .lastCell {
      width: 50%;
      position: absolute;
      top: 0px;
      bottom: 0px;
    }
    .firstCell,
    .headerPane {
      background-color: var(--neutralDarkest);
      left: 0px;
    }
    .lastCell,
    .itemPane {
      background-color: var(--themeDark);
      right: 0px;
    }
    .contentCell {
      display: flex;
      width: 80%;
      z-index: 10;
      justify-content: center;
    }
    .headerPane,
    .itemPane {
      display: inline-block;
      width: 25%;
      max-width: 220px;
    }
    .headerPane {
      display: flex;
      font-size: 26px;
      white-space: nowrap;
      justify-content: center;
      align-self: center;
      padding: 0px 20px;
    }
    .itemPane {
      position: relative;
      padding: 20px 30px 50px 30px;
      .title {
        font-size: 16px;
        font-weight: 600;
        padding-bottom: 20px;
      }
      .viewLink {
        padding-left: 30px;
        color: var(--neutralLighter);
      }
    }
  }
  .error {
    padding: 20px 40px;
    background-color: #ffeeee;
  }
}
.viewLink {
  text-decoration: none;
  display: inline-block;
  padding-top: 20px;
  color: var(--themePrimary);
  position: absolute;
  bottom: 20px;
  left: 0px;
  right: 0px;
}
.footer {
  height: 26px;
  border-top: 1px solid var(--neutralQuaternaryAlt);
  vertical-align: middle;
  text-align: right;
  padding: 10px 20px;
  color: var(--neutralSecondary);
}
@media only screen and (min-width: $smallScreenWidth) {
  .appModuleContent {
    .whatsNewSectionCollapsed {
      position: sticky;
      height: 60px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      .viewLink {
        display: none;
      }
    }
  }
}
@media only screen and (max-width: $smallScreenWidth) {
  .appModuleContent {
    .headerContent {
      background-position-x: 160px;
    }
    .subjectContentPane {
      .arrowPane {
        display: none;
      }
    }
    .systemContentPane,
    .subjectContentPane {
      display: block;
      .systemPane,
      .subjectPane {
        width: 90%;
        min-height: 100px;
        padding: 10px;
      }
    }
    .whatsNewSection {
      display: block;
      .headerPane,
      .itemPane {
        display: block;
        width: 100%;
        max-width: 100%;
        white-space: pre-wrap;
        color: var(--neutralLighter);
        font-size: small;
        padding: 5px 5px 50px 5px;
      }
      .headerPane {
        display: inline-block;
      }
      .contentCell {
        display: block;
        width: 100%;
      }
      .title,
      .text {
        padding: 0 5px 20px 0;
      }
      .viewLink {
        padding: 0 5px 0px 0;
      }
      .firstCell,
      .lastCell {
        display: none;
      }
    }
  }
  .viewLink {
    margin-top: 30px;
    padding-top: 0px;
    position: relative;
  }
}
.spinner {
  padding: 40px;
}
