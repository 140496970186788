.root {
  width: 180px;
  text-align: center;
  .question,
  .title {
    padding: 4px 8px;
    font-size: 14px;
  }
  .title {
    background-color: var(--themePrimary);
    color: var(--white);
  }
  .question {
    padding: 6px;
  }
  .comment {
    padding: 6px 10px;
  }
  .doneButton {
    margin: 6px 10px 10px 6px;
  }
}
