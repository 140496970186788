@import "../common/commonStyles.scss";

.root {
  position: relative;
  width: 100%;
  .mainContent {
    position: absolute;
    left: 0px;
    height: 100%;
    transition: right 0.4s;
  }
  .sideContent {
    position: absolute;
    right: 0px;
    height: 100%;
    transition: width 0.4s, opacity 0.4s;
  }
  .externalLinkPane {
    padding: 20px;
    a {
      font-weight: 500;
      color: var(--themeDark);
    }
  }
  .spinner {
    padding-top: 20px;
  }
}
@media only screen and (max-width: $smallScreenWidth) {
  .root {
    .mainContent,
    .sideContent {
      position: relative;
      width: 100%;
    }
    .sideContentToggleIcon {
      display: none;
    }
  }
}
