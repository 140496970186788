.root {
  width: 100%;
  position: relative;
  .type,
  .id {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 2px;
    text-align: center;
  }
  .type {
    font-weight: 500;
  }
  .id {
    font-weight: 300;
    font-size: smaller;
    width: calc(100% - 4px);
  }
  .idHighlight {
    font-size: 12px;
  }
  .icon {
    position: absolute;
    top: 4px;
    left: 4px;
    background-color: white;
    border-radius: 12px;
  }
}
